import { PlatformType } from '../../type/platform.type';
import { ConnectorType } from '@core/data/type/connector.type';

export class Oauth2IntegrationInitDto {
  constructor(
    public platformType: PlatformType,
    public importResourceAutomatically = true
  ) {
  }
}
