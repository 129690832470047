import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@core/data/model/platform';
import { Oauth2IntegrationInit } from '@core/data/model/oauth2-integration-init';
import { Oauth2IntegrationInitDto } from '@core/data/model/dto/oauth2-integration-init.dto';
import { OrganizationPlatform } from '@core/data/model/organization-platform';
import { PlatformConnectionDto } from '@core/data/model/dto/platform-connection.dto';
import { PlatformType } from '../type/platform.type';
import { OrganizationPlatformPatchDto } from '@core/data/model/dto/organization-platform-patch.dto';
import { BaseHttpService } from '@core/services/http/base-http.service';

@Injectable()
export class IntegrationService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('integration-service', httpClient);
  }

  initOauth2Integration(platform: Platform, importResourceAutomatically = false): Observable<Oauth2IntegrationInit> {
    return this.post<Oauth2IntegrationInit>(
      '/integrations/init-oauth2',
      new Oauth2IntegrationInitDto(
        platform.type,
        importResourceAutomatically
      ));
  }

  findAllPlatforms(): Observable<Platform[]> {
    return this.get<Platform[]>('/integrations/platforms');
  }

  findAllOrganizationPlatforms(): Observable<OrganizationPlatform[]> {
    return this.get<OrganizationPlatform[]>('/integrations');
  }

  createConnection(platformType: PlatformType, platformConnection: PlatformConnectionDto): Observable<OrganizationPlatform> {
    return this.post<OrganizationPlatform>(`/integrations/${platformType}/connections`, platformConnection);
  }

  disconnectConnection(platform: Platform): Observable<OrganizationPlatform> {
    return this.delete<OrganizationPlatform>(`/integrations/${platform.type}/connections`);
  }

  patchOrganizationPlatform(platformType: PlatformType, patch: OrganizationPlatformPatchDto): Observable<OrganizationPlatform> {
    return this.patch<OrganizationPlatform>(`/integrations/${platformType}`, patch);
  }
}
